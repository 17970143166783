.btnContainer {
  padding-top: 2em;
  padding-bottom: 1em;
}

.filterActivityHeader {
  font-size: 17px;
  font-family: boldFont;
  padding-bottom: 10px
}

.buttonList {
  padding-left: 15px;
}

.filterImg {
  position: absolute;
  width: 28px;
  right: 0;
  cursor: pointer;
}
