.displayFlexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.justifyCenter {
  display: flex;
  justify-content: center;
}

.confirmChangesButton {
  width: 150;
  margin-top: 10;
}

.subTotalRow {
  background: #F1F1F1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  font-size: 14px;
  margin-bottom: 20px;
  font-family: bookFont;
}

.confrimDowngradeBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-bottom: 10px;
  font-family: bookFont;
}

.confrimDowngradeBoxTitle {
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  padding-bottom: 10px;
}

.durationBoxWrap {
  display: flex;
  justify-content: center;
  font-family: bookFont;
  font-size: 10px;
  margin-bottom: 8.5px;
}

.durationBox {
  border-radius: 10px;
  padding: 30px 40px;
  font-size: 14px;
  font-family: boldFont;
  /* cursor: pointer; */ /* Because the monthly plan duration is the only option avaliable at this time we do not need this to be a pointer */
}

.cancelChanges {
  color: #0970C8;
  margin-top: 17px;
  cursor: pointer;
  font-size: 13px;
  font-family: boldFont;
}

.noDevicesWrap {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.editPlansButton {
  width: 172px;
  margin-top: 10px;
  float: right;
}

.tableScroll {
  height: auto !important;
}

.viewPlans {
  cursor: pointer;
  color: blue;
}

.tableFontGreyBig {
  font-family: bookFont;
  font-size: 14px;
  color: #989898;
  font-weight: normal;
}

.tableFontGreySmall {
  font-family: bookFont;
  font-size: 10px;
  color: #989898;
  font-weight: normal;
}

.tableFontBlack {
  font-family: bookFont;
  font-size: 14px;
  color: #000000;
  font-weight: normal;
}

.subscriptionHeading {
  font-size: 26px;
}

.tableWrapper {
  table-layout: auto;
}

.tableUnderlineText {
  border-bottom: none;
  padding: 0;
  text-decoration: underline;
}

.planDurationWrap {
  margin-top: 35px;
  margin-bottom: 8.5px;
}

.boxLeftMargin {
  margin-left: 23px
}

.deviceDescription {
  font-family: bookFont;
  font-size: 14px;
  color: #989898;
}
