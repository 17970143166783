.menuItem {
  padding: 14px 5px 14px 30px;
  margin: 12px 0;
  cursor: pointer;
  position: relative;
  font-size: 13px;
}

.menuItem img {
  padding-right: 15px;
  padding-left: 5px;
  vertical-align: middle;
}

.activeMenu {
  position: absolute;
  width: 8px;
  left: 10px;
  top: -2px;
  height: 54px;
  background: #FFD200;
}
