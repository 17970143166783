.NotificationBackup {
    left: 0 !important;
    right: 0 !important;
    top: 3.9em !important;
  }
  
  .NotificationBackup .MuiSnackbarContent-root {
    width: 100%;
    border-radius: 0;
    background: #FFD200;
    color: #FFFFFF;
    padding-top: 0;
    padding-bottom: 0;
  }
  
  .NotificationBackup  .MuiSnackbarContent-message {
    width: 98%;
    text-align: center;
    font-size: 10.5px;
    font-family: boldFont;
  }

  .NotificationCloseBackup {
    cursor: pointer;
    font-size: 1.4em;
    color: #FFFFFF;
  }

  .NotificationBackup-error {
    background: #B52A2A !important;
    color: #FFFFFF !important;
  }

  .NotificationBackup-message {
    background: #0970C8 !important;
    color: #FFFFFF !important;
  }

  /* .Notification-alert {
    background: #0970C8 !important;
    color: #FFF !important;
  } */