.tooltipTitle {
  padding: 16px 10px;
  display: inline-block;
  width: 212px;
  font-size: 10px;
  font-family: 'bookFont';
} 

.fullPlanCompLink {
  color: #0970C8;
  margin-top: 10px;
  cursor: pointer
} 

.tooltipIcon {
  width: 15px; 
  height: 15px;
  margin-right: 6px;
  cursor: pointer;
}

.tooltipText {
  font-weight: bold;
}

.greyText {
  color: #989898;
}

.displayFlex {
  display: flex;
}

.plusIcon {
  color: #FFD200;
  margin-right: 2px;
}

.smpTooltipTitle {
  height: auto;
  width: 200;
}
