.ActionBtns {
  display: flex;
  margin-top: 1.5rem;
}

.imgContainer {
  position: relative;
  width: 288px;
  height: 162px;
  cursor: pointer;
}

.imgContainer .after {
  position: absolute;
  top: 0;
  left: 0;
  width: 97%;
  height: 100%;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .3);
}

.cropWrap{
  height: calc(100vh - 12.6em);
  width:  calc((100vh - 12.6em) * 1.7777);
}

.activityZoneContainer {
	height: calc(100vh - 12.6em) !important;
  position: relative;
  width:  calc((100vh - 12.6em) * 1.7777) !important;
}

.refreshText {
  float: left;
  font-size: 14px;
  font-family: bookItalicFont;
  cursor: pointer;
  color: #0970C8;
}

.canvasWrap {
  width: 288px;
  height: 162px
}

.imageWrap {
  display: none;
  width: 288px;
  height: 162px;
}

.forwardIcon {
  padding-left: 5px;
}
