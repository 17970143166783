.deviceIconsText {
  font-size: 10px;
  padding: 0 5px;
}

.talkIcon {
  height: 29px;
  vertical-align: middle;
}

.screenShotIcon {
  width: 25px;
}

.recordIcon {
  width: 20px;
}

.sirenIcon {
  width: 30px;
}

.activeIcon {
  color: #FFD200;
  font-family: boldFont;
}
