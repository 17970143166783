.header {
	font-family: boldFont !important;
	font-size: 17px;
	padding-top: 2.5em;
}

.deviceSubHeader,
.techInfo {
	font-family: boldFont;
	font-size: 14px;
}

.techInfo p {
	font-family: bookFont;
}

.techInfo {
	padding: 15px 0px;
}

.techInfoButton {
	padding-top: 35px;
}

.inputClass {
	width: 80% !important;
	padding-top: 20px;
	font-size: 14px !important;
}

.buttonSet {
	padding-top: 20px;
}

.InlineBlock {
	display: inline-block;
}

.forwardButton {
	padding-left: 5px !important;
	width: 7px !important;
	cursor: pointer !important;
}

.setting,
.horLine {
	padding: 14px 0px;
	border-bottom: 1px solid #e9e9e9;
}

.deleteClass {
	color: #B52A2A;
	cursor: pointer;
}

.disabledDeleteBtn {
	opacity: 0.5;
	cursor: auto;
}

.toggleIcons {
	width: 20px;
	vertical-align: middle;
	margin-right: 10px;
}

.toggleItem {
	padding-bottom: 1px;
}

.settingPanel {
	height: calc(100vh - 4.1em) !important;
	background: #FAFAFA;
}

.display {
	color: #989898;
	padding-right: 5px;
}

.displayFlex {
	display: flex;
	justify-content: space-between;
}

.actTriggerHorLine {
	padding: 10px 0px;
	border-bottom: 0.7px solid #e9e9e9;
}

.SoundSliderContainer {
	padding: 0 10px 0 7px;
}

.SmallText {
	font-size: 12px;
	color: #000000;
	margin: 15px 0px !important;
	width: 91%;
}

.settingPanelPadding {
	padding: 0 20px 0 7px;
}

.blurOverlay {
	opacity: 0.2;
	pointer-events: none;
}

.iconWidth {
	width: 20px;
	vertical-align: middle;
	cursor: pointer;
}

@media all and (max-width: 1023px) {
	.indSetting {
		font-size: 11px;
	}
}

.fMVolumeText {
	font-size: 12px;
	margin-top: -5px;
}

.fmChannels {
	font-size: 13px;
	padding: 5px 0;
}

.fmVolumeSlider {
	margin-top: 2.5em;
	padding: 0 5px 0 10px;
}

.fmScanChannel {
	color: #0970c8;
}

.fmTurnOff {
	color: #B52A2A;
	margin-bottom: 1em;
}

.disabledBtn {
	opacity: 0.2;
	cursor: auto;
}

.fmClose {
	right: 20px;
	top: 20px;
	position: absolute;
	cursor: pointer;
}

.fm_channel {
	color: #000;
	border: none;
	background: #FAFAFA;
	font-size: 2em;
	text-align: right;
	width: 2.5em;
}

.fm_channel:focus {
	outline: none;
	border-bottom: 1px solid #CCC;
}

.hide_span {
	position: absolute;
	height: 0;
	overflow: hidden;
	white-space: pre;
	font-size: 2em;
}

.locateMe {
	color: #0970C8;
	cursor: pointer;
	display: flex;
	padding-left: 28%;
	padding-top: 2%;
	font-size: 14px;
	font-family: bookItalicFont;
}

.triggerInfo {
	color: #989898;
	font-size: 13px;
	width: 90%;
	margin: 2px;
}

.closeIcon {
	float: right;
	margin-top: 10px;
	cursor: pointer;
}

.soundInfo {
	font-size: 12px;
	margin: 15px 15px 5px;
	color: #989898;
}

.motionButtons {
	display: flex;
	justify-content: center;
	margin: 20px 20px 10px;
}

.subscrptionTooltipLink {
	color: blue;
	cursor: pointer;
}

.subDetail {
	font-family: bookFont;
	font-size: 14px;
}

.fwUpdate {
	color: #3498DB;
	padding-left: 75px;
	cursor: pointer;
	font-family: bookItalicFont;
	font-size: 14px;
}

.forwardIcon {
	width: 7px;
	padding-left: 2px;
}

.sectionHeader {
	font-family: spaceMonoItalic;
  font-size: 10px;
  border-bottom: 0.7px solid #ccc;
  text-transform: uppercase;
	margin-top:3em;
	padding-bottom: 5px;
}

.ArrowDown {
	position: absolute;
	right: 5px;
	color: #000;
	width: 15px;
	height: 15px;
}
