.sliderBar {
  height: 30px;
  width: 30em;
  background-image: repeating-linear-gradient(to right, transparent 30px 39px, white 10px 40px);
  align-self: center
}

.sliderWrap {
  height: 36px;
  width: 100%;
  /* margin: 50%;
  margin-top: 40%; */
}

.sliderThumbWrap {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  outLine: none;
}

.sliderThumb {
  border: 3px solid #FFD200;
  padding: 8px;
  border-radius: 50%;
  font-size: 12px;
  width: 14px;
  margin: 50%;
  color: black;
}

.videoSliderWrap {
  position: absolute;
  z-Index: 999;
  top: 50%;
  left: 25%;
}
