@font-face {
  font-family: bookFont;
  src: url(./assets/fonts/CircularPro-Book.otf);
}

@font-face {
  font-family: bookItalicFont;
  src: url(./assets/fonts/CircularPro-BookItalic.otf);
}

@font-face {
  font-family: blackFont;
  src: url(./assets/fonts/CircularPro-Black.otf);
}

@font-face {
  font-family: blackItalicFont;
  src: url(./assets/fonts/CircularPro-BlackItalic.otf);
}

@font-face {
  font-family: boldFont;
  src: url(./assets/fonts/CircularPro-Bold.otf);
}

@font-face {
  font-family: boldItalicFont;
  src: url(./assets/fonts/CircularPro-BoldItalic.otf);
}

@font-face {
  font-family: mediumFont;
  src: url(./assets/fonts/CircularPro-Medium.otf);
}

@font-face {
  font-family: mediumItalicFont;
  src: url(./assets/fonts/CircularPro-MediumItalic.otf);
}

@font-face {
  font-family: spaceMonoBold;
  src: url(./assets/fonts/SpaceMono-Bold.ttf);
}

@font-face {
  font-family: spaceMonoBoldItalic;
  src: url(./assets/fonts/SpaceMono-BoldItalic.ttf);
}

@font-face {
  font-family: spaceMonoItalic;
  src: url(./assets/fonts/SpaceMono-Italic.ttf);
}

@font-face {
  font-family: spaceMonoRegular;
  src: url(./assets/fonts/SpaceMono-Regular.ttf);
}

* {
  margin: 0;
  padding: 0;
  font-family: bookFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  font-display: auto;
}

::placeholder {
  /* #84313 - In Signin page and signup page place holder font is not matching with the requirement */
  /* font-style: italic !important; */
  /* font-family: bookItalicFont; */
  font-size: 17px;
}

.TextRight {
  text-align: right;
  padding-top: 8px;
}

.ForwardIcon {
  width: 7px;
  margin-left: 7px;
  vertical-align: middle;
}

@media (max-width: 770px) {
  .ForwardIcon {
    width: 5px !important;
    padding-bottom: 2px !important;
  }
}

.CursorPointer {
  cursor: pointer;
}

.TextCenter {
  text-align: center;
}

.TextLeft {
  text-align: left;
}

.BoldFont {
  font-weight: 600;
}

.Font10 {
  font-size: 10px;
}

.Font11 {
  font-size: 11.9px;
}

.Font12 {
  font-size: 12px;
}

.Font13 {
  font-size: 13px;
}

.Font14 {
  font-size: 14px;
}

.Font15 {
  font-size: 15px;
}

.PadBottom5 {
  padding-bottom: 5px;
}

.PadTop5 {
  padding-top: 5px;
}

.PadTop10 {
  padding-top: 10px;
}

.Pad5 {
  padding: 5px !important;
}

.Pad10 {
  padding: 10px !important;
}

.MarginBottom10 {
  margin-bottom: 10px;
}

.scrollarea .scrollbar-container {
  opacity: 0 !important;
}

.scrollarea:hover .scrollbar-container {
  opacity: 0.3 !important;
}

.video-js.vjs-16-9,
.video-js.vjs-4-3,
.video-js.vjs-fluid {
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  padding-top: 0 !important;
}

/* Custom toggle start */

.ToggleSwitch {
  position: relative;
  display: inline-block;
  width: 37px;
  height: 20px;
  vertical-align: middle;
}

.ToggleSwitch input {
  display: none;
}

.ToggleSliders {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0px;
  right: 0px;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.ToggleSliders:before {
  position: absolute;
  content: '';
  height: 15.4px;
  width: 15.4px;
  left: 0px;
  bottom: 1px;
  background-color: #fafafa;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .ToggleSliders {
  background-color: #000;
  border: 1px solid #000;
}

input:focus + .ToggleSliders {
  box-shadow: 0 0 1px #000;
}

input:checked + .ToggleSliders:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.ToggleSliders.ToggleRound {
  border-radius: 20px;
  border: 1px solid #ccc;
}

.ToggleSliders.ToggleRound:before {
  border-radius: 50%;
}

/* Custom toggle end */

.NoPadTop {
  padding-top: 0 !important;
}

.GreyColor {
  color: #989898 !important;
}

.DeviceSettings .MuiExpansionPanelSummary-root {
  min-height: 40px !important;
}

.DeviceSettings .MuiPaper-root {
  background: #fafafa !important;
}

.DeviceSettings .MuiExpansionPanel-root:before {
  background: #e9e9e9 !important;
  height: 0.7px !important;
}

.MuiSlider-root {
  color: #ffd200 !important;
}

.MuiSlider-rail {
  color: grey;
  height: 3px !important;
}

.MuiSlider-track {
  height: 3px !important;
  background: #ffd200;
}

.MuiSlider-thumb {
  width: 16px !important;
  height: 16px !important;
  margin-top: -8px !important;
}

.VideoCaption {
  display: none !important;
  visibility: hidden !important;
}

.CustomCheckbox {
  margin-left: 0 !important;
}

.CustomCheckbox .MuiFormControlLabel-label {
  font-size: 12px;
}

.TCText {
  font-family: boldFont !important;
  font-size: 13px;
  margin-left: 5px;
}

#NoDevices {
  font-size: 14px;
  padding: 6em 0 12em 8em;
}

#NoActivities {
  font-size: 14px;
  padding: 5em 0em 2em 8em;
}

#alert-dialog-description {
  margin-bottom: 0 !important;
}

#subscriber_container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

#pan_slider {
  visibility: hidden;
}

#subscriber_container:hover + #pan_slider,
#pan_slider:hover {
  visibility: visible;
}

#publisher_container {
  position: absolute;
  width: 100%;
  bottom: 10px;
  z-index: 100;
}

.CardHighlight:hover {
  background: #fafafa;
}

.ReactCrop {
  width: 100%;
}
.ReactCrop__image {
  width: calc((100vh - 12.6em) * 1.7777) !important;
  height: calc(100vh - 12.6em) !important;
}

.ReactCrop__crop-selection {
  border: 1px solid blue !important;
}

.ReactCrop .ord-ne::after {
  background-color: blue !important;
  border: unset !important;
}

.ReactCrop .ord-nw::after {
  background-color: blue !important;
  border: unset !important;
}

.ReactCrop .ord-se::after {
  background-color: blue !important;
  border: unset !important;
}

.ReactCrop .ord-sw::after {
  background-color: blue !important;
  border: unset !important;
}

.ReactCrop__drag-handle::after {
  background: none !important;
  border: none !important;
}

.TableHead .MuiTableCell-head {
  color: black !important;
  font-family: boldFont !important;
}

.PanicButton .MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 50px 12px 12px;
}

.ContainerScroll {
  height: calc(100vh - 4.1em) !important;
}

.gmnoprint.gm-style-cc + button,
.gm-fullscreen-control,
.gm-style-mtc,
.gm-svpc,
.gmnoprint {
  display: none;
}

.spinnerContainer {
  width: 100%;
  position: relative;
  min-height: 130px;
  height: 100%;
}

.Notification .MuiSnackbarContent-root {
  background: #ffd200;
  color: #000;
}

.Notification.MuiSnackbar-anchorOriginTopCenter {
  top: 4em;
}

.Notification-error {
  background: #b52a2a !important;
  color: #fff !important;
}

.Notification .MuiSnackbarContent-message {
  width: 98%;
  text-align: center;
}

.NotificationClose {
  cursor: pointer;
  font-size: 1.4em;
  position: absolute;
  top: 10px;
  right: 10px;
}

/* [#84615] horizontal scroll bar is showing up in profile menu for newly created accounts  */
#fade_menu .MuiPopover-paper {
  right: 2px;
  max-width: 255px !important;
  overflow-x: hidden !important;
}

.AlertTypeTableCell .MuiTableCell-root {
  padding: 14px 40px 14px 0px;
  font-family: bookFont;
  border-bottom: 0px solid rgba(224, 224, 224, 1);
}

.AlertTypeLeftAlign .MuiTableCell-alignCenter {
  text-align: left;
}

.ManageHomeFormInput .MuiInputBase-input {
  font-size: 14px !important;
}

.scrollContainer {
  height: 450px;
}

.UserNotificationContainer {
  height: calc(100vh - 8em) !important;
}

span.popoverArrowPointer {
  border: 1px solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  position: relative;
  top: 4.4em;
  right: 3.5em;
  background: #fff;
}

.popoverArrow {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.help .MuiCardContent-root {
  padding: 0px 10px;
}

.CustomSelect {
  font-size: 12px !important;
  color: #727272 !important;
}

.CustomSelect .MuiSelect-icon {
  display: none;
}

ul[aria-labelledby='custom_select_drp'] {
  height: 100%;
  max-height: 350px;
}

ul[aria-labelledby='custom_select_drp'] .MuiMenuItem-root {
  font-size: 12px !important;
  color: #727272;
  line-height: 15px;
}

.CustomSelect.MuiInput-underline:before,
.CustomSelect.MuiInput-underline:after {
  border-bottom: none !important;
}

.sruButton .MuiButton-outlined {
  border: 2px solid #b52a2a;
}

.Overlay {
  background-color: rgba(255, 248, 248, 0.5) !important;
  z-index: 10000 !important;
  pointer-events: none !important;
}

.noBorderRadius {
  border-radius: 0 !important;
}

html {
  overflow-y: hidden !important;
}

.notificationLink {
  cursor: pointer;
  font-weight: bolder;
  color: #0970c8;
}

ul[aria-labelledby='camera_select_drp'] .MuiMenuItem-root {
  font-size: 12px !important;
  color: #000;
  line-height: 15px;
  text-align: center;
  font-weight: bold !important;
}

ul[aria-labelledby='camera_select_drp'] .MuiMenuItem-root:first-child {
  border-bottom: 1px solid #dedede;
}

.cameraCustomSelect {
  font-size: 12px !important;
  color: #000 !important;
  font-weight: bold !important;
  margin-top: 1em;
}

/* .cameraCustomSelect .MuiSelect-icon {
  display: none;
} */

.cameraCustomSelect.MuiInput-underline:before,
.cameraCustomSelect.MuiInput-underline:after {
  border-bottom: none !important;
}

/* [#84888] Extra Element is coming in bottom of the application screen if we allow the microphone permission in live streaming page.(screen shots attached) */
.OT_publisher {
  visibility: hidden;
}

:root {
  --amplify-primary-color: #000;
  --amplify-primary-tint: #0c0c0c;
  --amplify-primary-shade: #0c0c0c;
}
