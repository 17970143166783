.contentBorder,
.contentBorder2 {
  padding-right: 1.2em;
  font-weight: bold;
  padding-bottom: 4px;
  font-family: bookFont !important;
}

.contentBorder {
  border-bottom: 8px solid #FFD200;
}

.contentBorder2 {
  border-bottom: 5px solid #FFD200;
  font-size: 17px;
  font-family: boldFont !important;
}

@media screen and (max-width: 700px) {
  .contentBorder {
     font-size: 15px !important;
  }
}
