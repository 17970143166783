.customDialog {
  position: relative;
}

.customDialog .MuiBackdrop-root {
  background-color: rgba(171, 169, 169, 0.5);
}

.customDialog .MuiDialog-paper {
  padding: 20px 30px;
}

.customDialog .MuiDialog-paperScrollPaper {
  border-radius: 0;
  box-shadow: none;
}

.customDialog .MuiDialogTitle-root {
  padding-bottom: 0;
}

.customDialog .MuiDialogTitle-root h2 {
  font-family: boldFont !important;
  color: #000 !important;
  font-size: 17px;
}

.customDialog .Close {
  position: absolute;
  left: 8px;
  top: 6px;
  cursor: pointer;
}

.customDialog .MuiDialogContent-root {
  font-size: 14px;
}

.customDialog .MuiDialogActions-root {
  display: block;
}

@media screen and (max-width:770px) {
  .customDialog .MuiDialog-paper {
    padding: 5px !important;
    border-radius: 10px;
  }  
}
