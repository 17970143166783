.inviteUser {
  color: #0970C8; 
  padding-top: 10px;
  cursor: pointer;
  margin-left: 8px;
}

.inviteUserText {
	font-size: 13px;
	font-family: bookItalicFont;
}

.invite {
	width: 93%;
}

.userEditIcon {
	position: absolute;
	z-index:1;
	top:10%;
	width:18px;
	right:16%;
	cursor:pointer;
}

.userDeleteIcon {
	position: absolute;
	z-index: 1;
	top:10%;
	width:18px;
	right:10%;
	cursor: pointer;
}

.addressFields {
	margin-bottom: 5px;
	padding-left: 10px;
	width:90%;
	font-size: 14px;
	font-family: boldFont;
}

.removeHome {
	align-content: center;
	color: #B52A2A;
	padding-top: 20px;
	cursor: pointer;
	margin-left: 10px;
	padding-left: 7.5em;
	font-size: 14px;
}

.authStyle {
	margin-bottom: 5px;
	width: 90%;
	padding: 20px 0px 0px 7px;
	font-family: boldFont;
	font-size: 14px;
}

.resendInvite {
	font-size: 14px;
	font-family: bookItalicFont;
	position: absolute;
	z-index: 1;
	top: 20%;
	right: 10%;
	color: #0970C8;
	cursor:pointer;
}
