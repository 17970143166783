.device {
	padding: 0 20px;
	position: relative;
}

.deviceStatus {
	position: absolute;
	right: 30px;
	top: 30px;
	display: flex;
}

.deviceDetails {
  padding: 0 20px;
}

.sruImage {
	max-width: 100%;
	height: auto;
}

.wrapper {
	display : flex;
	flex-direction: column;
	margin: 8px;
}

.batteryClass {
	text-align: center;
	font-size: 10px;
	margin: 3px !important;
}
