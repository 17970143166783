.CustomButton {
  text-transform: initial !important;
  border-radius: 3em !important;
  height: 28px;
  line-height: 17px !important;
  box-shadow: none !important;
}

.BlackButton,
.GreyButton,
.YellowButton,
.RedButton,
.LightGreyButton {
  font-family: boldFont;
  font-size: 13px;
}

.BlackButton {
  background: #000 !important;
  color: #FFFFFF !important;
}

.YellowButton {
  background: #FFD200 !important;
  color: #fff !important;
}

.disabledButton {
  background: #D8D8D8 !important;
  color:Black;
  font-weight: bold;
}
.GreyButton {
  background: #CCCCCC !important;
  color: #fff !important;
}

.RedButton {
  background: #f33131 !important;
  color: #fff !important;
}

.LightGreyButton {
  background: #7d7c7c !important;
  color: #FAFAFA !important;
}

@media screen and (max-width: 700px) {
  .BlackButton,
  .YellowButton,
  .LightGreyButton,
  .GreyButton {
    font-family: boldFont !important;
    font-size: 10px !important;
  }
  .btnWidth {
    width: 110px !important;
  }
  .forgotBtn {
    width: 150px !important
  }
}
