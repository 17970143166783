.activityDay {
  font-family: spaceMonoItalic;
  font-size: 9.1px;
  border-bottom: 0.7px solid #ccc;
  text-transform: uppercase;
  margin-bottom: 10px;
  padding-left: 10px;
}

.activityListContainer,
.rightContent {
  position: relative;
}

.activeItem {
  border-left: 8px solid #FFD200;
  position: absolute;
  height: 58.8px;
  left: -7px;
}

.activityItem {
  height: 58.8px;
  display: inline-block;
  border: 1px solid #f3f3f3;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.06)!important;
  margin-bottom: 8px;
  position: relative;
  cursor: pointer;
  margin-left: 5px;
}

.activityItem img {
  max-height: 59px;
  width: 100%;
}

.activityItem .icon {
  margin-right: 7px;
  width: 9px;
  height: 11px;
  padding-left: 8px;
  padding-top: 10px;
}

.activityItem .text,
.deletedSoonText {
  font-size: 9.8px;
}

.activityItem .time {
  padding-top: 1em;
  padding-left: 8px;
  font-size: 9.1px;
  color: #989898;
}

.activityItem .favImage {
  width: 10px;
  vertical-align: middle;
  margin-left: 5px;
  margin-top: -3px;
}

.activityDelete {
  position: absolute;
  width: 44px;
  height: 58.8px;
  right: 20px;
  top: 0;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(151, 151, 151, 0.42);
  -moz-box-shadow: 0px 0px 8px 0px rgba(151, 151, 151, 0.42);
  box-shadow: 0px 0px 8px 0px rgba(151, 151, 151, 0.42);
  display: none;
  cursor: pointer;
}

.activityListContainer:hover .activityDelete {
  display: block;
}

.activityDelete img {
  width: 11px;
  height: 15px;
  vertical-align: middle;
  padding-top: 40%;
  padding-left: 25%;
 }

.extraSmallVideo {
  min-width: 76px;
  max-width: 76px;
}

.smallVideo {
  min-width: 94px;
  max-width: 94px;
}

.normalVideo {
  min-width: 122px;
  max-width: 122px;
}

.largeVideo {
  min-width: 150px;
  max-width: 150px;
}

.leftContent {
  float: left;
  min-width: 81px;
  width: auto;
  padding-right: 4px;
}

.rightContent img {
  min-height: 59px;
}

.videoTime {
  position: absolute;
  left: 5px;
  bottom: 5px;
  color: #cecece;
  padding: 2px 4px;
  font-size: 9.1px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.4);
}

.filterActivityHeader {
  font-size: 17px;
  font-family: boldFont;
  padding-bottom: 10px
}

.btnContainer {
  padding-top: 2em;
  padding-bottom: 1em;
}

.deletedSoonIcon,
.toBeDeletedSoon {
  width: 14px !important;
  height: 14px !important;
}

.deletedSoonIcon {
  vertical-align: middle;
  margin-right: 5px;
}

.toBeDeletedSoon {
  position: absolute;  
  top: 22px;
  right: 4.3em;
}

.deletedSoonBlock {
  margin-top: 20px;
  margin-bottom: 10px;
}

.addMoreStorage {
  text-decoration: underline;
  cursor: pointer;
}

.loadMoreEvents {
  height: 5em;
  border: 1px solid transparent;
}
