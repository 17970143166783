.activityDetails {
  padding: 3px 20px 0 20px;
}

.actVideoSection,
.videoContainer {
  aspect-ratio: 16 / 9;
  width: 100%;
  position: relative;
}

.videoContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.streamVideoText {
  position: absolute;
  right: 4px;
  top: -19px;
  font-size: 10px;
}

.streamVideoText,
.streamVideoDeviceText {
  font-family: spaceMonoItalic;
  text-transform: uppercase;
  text-align: right;
}

.streamVideoError {
  color: #ffffff;
  position: absolute;
  left: 40%;
  top: 50%;
}
