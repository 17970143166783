.closeIcon {
  position: absolute;
  cursor: pointer;
  right: 0px;
  top: 10px;
  padding-right: 10px;
}

.cardWrapper {
  border-bottom: 1px solid #808080;
}

.flexCenter {
  display: flex;
  align-items: center;
}

.cardDetailsWrap {
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-size: 14px
}

.cardIcon {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.cardExpiryText {
  font-size: 12px;
  margin-left: 34px;
  margin-bottom: 10px;
}

.AddCardWrap {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
  margin-bottom: 10px
}

.addCardButton {
  background: #FFD200;
  color: #fff;
  font-size: 16px;
  font-family: inherit;
  border: none;
  border-radius: 4px;  
  padding: 12px 20px;
  display: block;
  width: 100%;
  letter-spacing: .5px;
  transition: .2s;
  cursor: pointer;
  border-radius: 3em !important;
}

.addCardWrap {
  max-width: 400px;
  border-radius: 8px;
  background: #fff;
}

.displayFlex {
  display: flex;
  margin-bottom: 15px;
}

.cardProperty {
  font-family: boldFont;
  font-size: 16px;
}

.cardValue {
  font-family: bookFont;
  font-size: 16px;
  margin-left: 10px;
}

.columnHeading {
  font-family: boldFont;
  font-size: 18px;
}

.manageCardWrap {
  padding: 30px;
}

.cardError {
  display: flex;
  justify-content: center;
  margin-top: 25%;
  color: #e41029;
}
