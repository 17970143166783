.AllActivity {
  margin-right: 1em;
  background: #eee;
  width: 202px;
  margin-bottom: 0.5em;
  height: 127px;
  position: relative;
  cursor: pointer;
}

.AllActivity span {
  position: absolute;
  left: 25%;
  top: 43%;
  font-size: 14px;
}

.AllActivity span img {
  width: 8px !important;
  vertical-align: middle;
  margin-left: 5px;
}
