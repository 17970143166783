.AppHeader {
  background: #000;
  height: 62px;
  color: #FFF;
  padding: 0 20px;
  font-size: 13px;
}

.MarginLeft20 {
  margin-left: 20px !important;
}