.BGImage {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  z-index: -1;
}