.CustomInput .MuiInputBase-input {
	padding-left: 8px;
  font-size: 17px;
	font-family: bookFont;
}

.CustomInput .MuiInputBase-input:focus {
	border-top: 1px solid #ffd200 !important;
	border-left: 1px solid #ffd200 !important;
	border-right: 1px solid #ffd200 !important;
}

.CustomInput.MuiInput-underline:after,
.CustomInput.MuiInput-underline:before {
	border-bottom: 1px solid black !important;
}

.CustomInput .MuiInputBase-input.error {
  border: 1px solid #B52A2A;
}

.ProfileInput.MuiInput-underline:after,
.ProfileInput.MuiInput-underline:before {
	border-bottom: 0px solid #E9E9E9 !important;
}

.ProfileInput .MuiInputBase-input .MuiInput-input {
	width: 150% !important;
	cursor:pointer !important;
}

.EyeIconPadding .MuiInputBase-input {
	padding-right: 2.5em;
}

.InfoIconPadding .MuiInputBase-input {
	padding-right: 4em;
}

.infoIconStyle {
	position: absolute;
	right: 2.2em;
	padding-bottom: 12px;
}

.infoImage {
	width: 22px;
	height: 22px;
	cursor: pointer;
}
