.inviteText {
  font-size: 14px;
}

@media (max-width: 770px) {
    .CardContainer {
      width: 280px !important;
      /* height: 180px !important; */
    }
    .inviteText {
      font-size: 12px;
    }
  }
  
