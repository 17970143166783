.backBtn {
  padding-top: 5px;
  font-size: 12px;
  font-family: boldFont;
  cursor: pointer;
}

.backBtn img {
  vertical-align: middle;
  margin-right: -3px;
  margin-top: -5px;
  width: 18px;
  margin-left: -4px;
}
