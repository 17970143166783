.profileMenuBg {
  /* background: #FAFAFA; */
  border-right-color: #eee;
  border-right-width: 1px;
  border-right-style: solid;
}

.headerText {
  font-size: 1.6em;
  padding-top: 1.3em;
}

.borderBottom {
  padding-top: 2em;
  border-bottom: 1px solid #e9e9e9;
}

.greyLabel {
  color: #989898;
  padding-top: 0.4em;
}

.changeText {
  color: #0970c8;
  cursor: pointer;
}

.forwardIcon {
  vertical-align: middle;
  height: 10px;
  margin-left: 2px;
}

.profilePhoto {
  display: none;
}

.privacySettingWrapper {
  border: 2px solid #f1f1f1;
  margin-top: 20px;
  height: calc(100vh - 11em) !important;
}

.privacyContent {
  padding: 10px 70px 10px 30px;
}

.privacyHeading {
  padding: 10px 20px 10px 30px;
  font-family: boldFont;
}

.textStyle {
  padding-top: 0.9em;
}

.tcService {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
}

.containerPadding {
  padding: 1.5em 0 10px 4em;
}

.profileContentBlock {
  height: calc(100vh - 8.4em) !important;
}

.contentPadRight {
  padding-right: 4em;
  padding-bottom: 10px;
}
