.Profile {
  width:50px;
  height: 50px;
  border-radius: 50%;
}

.fade_menu {
  margin-top: 0.4em;
}

.fade_menu .menu_item {
  padding: 5px 25px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
}

.checkMarkActive {
  vertical-align: middle;
  margin-top: -3px;
  margin-left: -2em;
  width: 20px;
  position: absolute;
}

.fade_menu .menu_item:hover {
  background: #FAFAFA;
}

span.arrowPointer {
  border: 1px solid #FFF;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  position: absolute;
  top: 4.4em;
  right: 3.5em;
  background: #FFF;
}

.upArrow {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.HorLine {
  background: #E9E9E9;
  height: 0.7px;
  margin: 5px 10px 0px 10px;
  min-width: 180px;
  width: auto;
}

.fade_menu .manage_homes {
  color: #06b0cc;
  font-size:11px;
  margin-top: 5px;
}

.fade_menu .version {
  color: #06b0cc;
  font-size:12px;
  cursor: default;
}

.fade_menu .profile_menu_block {
  height: auto;
}

.fade_menu .logout {
  color: #B52A2A;
}

.ellipsisText {
  font-family: boldFont;
  font-size: 17px;
  margin-bottom: 5px;
}

.ellipsisText:hover {
	cursor: default;
}
