.image, .fmOFF {
	width: 30%;
	padding-left: 1em;
}

.image {
	cursor: pointer;
}

.panicButtonOn,
.panicButtonOff {
	width: 239px !important;
	height: 39px !important;
	margin-left: 0.8em !important;
}

.panicButtonOn {
	background: #B52A2A !important;
	color: #fff !important;
	padding-right: 6.8px;
}

.panicButtonOff {
	background:#FFFFFF !important;
	color: #B52A2A !important;
	border: 1px solid #bbb;
}

.customButton {
	text-transform: initial !important;
	border-radius: 3em !important;
	height: 28px;
	line-height: 17px !important;
	box-shadow: none !important;
}

.actionButtonName {
	font-size: 17px;
	padding-left: 5px;
	padding-top: 5px;
}

.pushToSilence {
	color: #B52A2A;
	font-size: 9px;
	padding-left: 20%;
	padding-top: 3px;
}

.panicButton {
	padding: 0px 4px;
	margin-top: 3px;
	font-size: 12px;
	font-family: boldFont;
}

@media all and (max-width: 1023px) { 
	.alignButtons {
	  width: 50%
	}

	.panicButtonOff {
		width: 200px !important;
	}

	.panicButtonOn {
		width: 200px !important;
	}

	.buttonwrapper {
		padding-left: 1.5em;
	}

	.actionButtonName {
		font-size: 12px;
	}
}
