.activityList {
  margin-top: 1em;
  white-space: nowrap;
  width: calc(100% - 0.5em) !important;
  height: 170px !important;
}

.activityList img {
  width: 100%;
  height: auto;
  max-width: 203px !important;
  max-height: 114px !important;
}

.activityList .icon {
  margin-right: 7px;
  width: 12px;
  height: 12px;
}

.activityList .time {
  font-size: 12px;
  color: #989898;
  float: right;
  padding-top: 4px;
}

.filterHeader {
	font-size: 11.9px;
	font-family: boldFont;
  border-bottom: 0.7px solid #e9e9e9;
  padding-bottom: 3px;
}

.filterItem {
  padding: 5px 15px 5px 10px;
  font-size: 11.9px;
}

.filterItemIcon {
  vertical-align: middle;
  width:20px;
  margin-right: 10px;
}

.activityListItems {
  margin-right: 1em;
  cursor: pointer;
  margin-bottom: 0.5em;
}

.clearDate,
.resetFilter {
  color: #0970C8;
  cursor: pointer;
}

.clearDate {  
  padding-right: 13px;
}

.setDate {
  color: #727272;
}

.resetFilter {
  font-size: 12px;
}

.clearDate img,
.resetFilter img {
  width: 7px;
  vertical-align: middle;
}
