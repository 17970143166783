.timezoneBlock {
	padding-top: 6px;
  font-size: 14px;
  border-bottom: 1px solid #000;
}

.timezoneLabel {
	padding-left: 7px;
}

.selectedTimezone {
	color: #727272;
	cursor: pointer;
	position: relative;
}

.forwardArraowIcon {
	width: 15px;
	vertical-align: middle;
	color: #000;
}

.searchZoneInput {
	border: 1px solid #ccc;
    padding: 5px;
    font-size: 14px;
    width: 96%;
    margin-top: 10px;
}

.allZoneNames {
	min-height: 18em !important;
	height: auto;
	width: 300px !important;
}

.allZoneNames > div {
	overflow-x: hidden !important;
}

.zoneName {
	padding: 5px 0;
	border-bottom: 1px solid;
	width: 100%;
}
