#videoPlayer {
  width: 100%;
  height: 100%;
}

#videoPlayer .vjs-control-bar {
  height: 5.5em;
}

#videoPlayer .vjs-time-control,
#videoPlayer .vjs-volume-panel-horizontal {
  padding-top: 1.3em;
}

#videoPlayer .vjs-vol-0,
#videoPlayer .vjs-vol-3 {
  outline: none;
}

#videoPlayer .vjs-paused,
#videoPlayer .vjs-playing {
  margin-top: 1.3em;
  outline: none;
}

#videoPlayer .vjs-slider {
  height: 23px;
}

#videoPlayer .vjs-play-progress:before {
  width: 8px;
  height: 23px;
  display: block;
  top: 0;
  background: #FFD200 !important;
  content: '';
}

#videoPlayer .vjs-volume-bar.vjs-slider-horizontal {
  height: .3em;
}

#videoPlayer .customVJSIcon {
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
  visibility: hidden;
}

#videoPlayer .customVJSIcon .btnWrapper {
  cursor: pointer;
}

#videoPlayer .customVJSIcon img {
  width: 11px;
  margin-bottom: 4px;
  margin-top: 10px;
}

#videoPlayer .customVJSIcon .iconText {
  font-size: 9px;
}

#starVJSBtn img {
  width: 17px !important;
  margin-top: 8px !important;
}

#screenshotVJSBtn img {
  width: 18px !important;
}

#downloadVJSBtn {
  margin-left: 15px !important;
}

#downloadVJSBtn img {
  width: 12px !important;
}

#screenshot_canvas {
  position: absolute;
  visibility: hidden;
}

.disabledIcon {
  pointer-events: none;
  opacity: 0.4;
}

#shareVJSBtn .tooltiptext {
  visibility: hidden;
  width: 280px;
  height: 100px;
  background-color: white;
  color: black;
  text-align: center;
  padding: 10px 0;
  font-size: 10px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  right: 1px;
  margin-left: -60px;
  font-family: bookFont;
  font-size: 10px;
  cursor: auto;
}

#shareVJSBtn .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  /* At the bottom of the tooltip */
  left: 92%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

#shareVJSBtn .tooltiptext .shareButtonWrap {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  font-family: boldFont;
  font-size: 9px;
}

#shareVJSBtn .tooltiptext .linkWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  font-family: bookFont;
  font-size: 8px;
}

#shareVJSBtn .tooltiptext .copyButton,
#shareVJSBtn .tooltiptext .doneButton {
  color: #fff;
  border-radius: 3em;
  height: 20px;
  width: 75px;
  line-height: 17px;
  box-shadow: none;
  cursor: pointer;
  outline: none;
}

#shareVJSBtn .tooltiptext .copyButton {
  background: #FFD200;
}

#shareVJSBtn .tooltiptext .doneButton {
  background: #cccccc;
  margin-left: 10px;
}

#shareVJSBtn .tooltiptext .shareUrl {
  font-family: bookItalicFont;
  color: #0970C8;
  text-decoration: underline;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: none;
  font-family: bookFont;
  font-size: 8px;
}

/* Headphone icon is displaying in video player controller. */
.vjs-audio-button {
  display: none;
}
