.InstructionContainer {
  height: calc(100vh - 10.5em);
  text-align: center;
}

.Step {
  padding-bottom: 5px;
}

.BoldText {
  font-family: boldFont;
  font-size: 13px;
}

.InstructionContainer img {
  padding: 1.5em 0;
  max-width: 100%;
}

.InstructionText {
  font-size: 16px;
  font-family: boldFont;
}

.TrubleShooting {
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;
  float: right;
}

.InstructionTextHeader {
  padding-top: 40px;
  font-family: boldFont;
  font-size: 17px;
}
