.deviceList {
  margin-top: 1em;
  margin-bottom: 2em;
  white-space: nowrap;
  width: calc(100% - 0.5em);
  height: 245px !important;
}

.deviceList .items {
  padding-top: 10px;
  margin-right: 1em;
  display: inline-block;
  position: relative;
}

.deviceList .text {
  font-size: 17px;
}

.connectonStatus {
  position: absolute;
  font-size: 10px;
  font-family: spaceMonoRegular;
  text-transform: uppercase;
  left: 9px;
  top: 17px;
}

.imageContainer,
.s3ImgContainer {
  border: 1px solid #D8D8D8;
  cursor: pointer;
  width: 350px;
  height: 200px;
  margin-bottom: 5px;
  text-align: center;
  background: #F1F1F1;
}

.overDoorAndTravelCamera,
.sruDeviceImg,
.s3Image {
  height: 100%;
  max-height: 100%;  
}

.s3Image {
  width: 100%;
  max-width: 100%;
}

.currentPlan {
  position: absolute;
  bottom: 2.2em;
  left: 2px;
  background: #000;
  color: #FFF;
  padding: 6px 10px 4px 10px;
  font-size: 0.em;
  font-size: 12px;
  border-top-right-radius: 10px;
}
