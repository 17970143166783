.form {
	margin-bottom: '5px';
	padding-left: '10px';
	width: '90%';
	padding-bottom: '10px';
}

.manageHomePadding {
	padding: 0 20px;
}

.locateMe {
	color: #0970C8;
	cursor: pointer;
	padding-top: 10px;
	padding-left: 290px;
	font-size: 14px;
	font-family: bookItalicFont;
}

.editHome {
	color: #00aae4;
	padding-bottom: 10px;
	font-size: 14px;
}

.buttonSet {
	padding-top: 20px;
	padding-left: 14px;
}

.addressLineTwo {
	margin-left: 5px;
}

.topPadding {
	padding-top: 20px;
	font-size: 17px;
	font-family: bookFont;
}

.usersPanel {
	height: auto;
	background: #FAFAFA;
}

.addNewHome {
	margin: 1em;
	padding-top: 6px;
	color: #00aae4;
	font-size: 14px;
	font-family: bookFont;
}

.addNewHome img {
	width: 25px;
	vertical-align: middle;
	padding-right: 5px;
}

.nickName {
	width: 90%;
	font-size: 14px;
	font-family: boldFont;
}

.differentLocation {
	margin-bottom: 5px;
	padding-left: 10px;
	font-size: 14px;
	font-family: boldFont;
}

.homeCard {
	margin-bottom: 15px;
	border-bottom: 1px solid #CCCCCC;
	width: 86%;
	height: 100%;
}

.homeCardName {
	padding-bottom: 8px;
	font-family: boldFont;
	font-size: 17px;
}

.suggestionContainer{
	font-size: 12px;
}

.suggestionActive {
	background-color: #FFD200;
}

.suggestion {
	font-family: bookItalicFont !important;
}
