.streamVideoContainer {
  aspect-ratio: 16 / 9;
  width: 100%;
  position: relative;
}

.streamVideoText {
  position: absolute;
  right: 4px;
  top: -20px;
  font-size: 10px;
  font-family: spaceMonoItalic;
  text-transform: uppercase;
}

.streamVideoDeviceText {
  font-family: spaceMonoItalic;
  text-transform: uppercase;
  text-align: right;
}

.streamVideoError {
  color: #ffffff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.connected {
  height: 8px;
  width: 8px;
  background-color: #ffd200;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
}

.logoStyle {
  height: 80px;
  padding-bottom: 10px;
}

.recordingTimerBlock {
  position: absolute;
  z-index: 999;
  right: 2px;
}

.initializeCamera {
  background-image: url('../../../../assets/images/VideoSplashImage.png');
  background-size: cover;
  background-position: center;
}

.initializeCamera::after {
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
