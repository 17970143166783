
  .ex1.container {
    margin: auto;
    padding: 30px 20px;
    min-height: 45vh;
  }

  .ex1-wrap{
    margin: auto;
    max-width: 400px;
    border-radius: 8px;
    background: #fff;
    padding: 10px 32px 0px 32px;
  }

  .ex1-field{
    position: relative;
    margin-bottom: 32px;
  }
  .ex1-select{
    position: relative;
    margin-bottom: 52px;
  }
  .select{
    font-size: 14px;
    font-weight: 500;
    color: #7b808c;
    border: 0.0625rem solid #999;
    border-radius: 0.25em;
  }
  .ex1-fields{
    display: flex;
    margin-left: -16px;
    margin-bottom: 32px;
  }
  .ex1-fields .ex1-field{
    flex: 1;
    margin-left: 16px;
  }
  .ex1-label{
    font-size: 12px;
    font-weight: 500;
    color: #7b808c;
    position: absolute;
    top: 0.25rem;
    pointer-events: none;
    padding-left: 0.125rem;
    z-index: 1;
    font-weight: normal;
    -webkit-transition: all 0.28s ease;
    transition: all 0.28s ease;
  }
  .ex1-input{
    width: 100%;
    display: block;
    background: transparent;
    border-radius: 0;
    border: none;
    padding: 4px 2px;
    border-width: 0;
    border-color: transparent;
    color: #333;
    font-size: 16px;
    font-family: inherit;
    font-weight: 500;
    transition: .2s;
    cursor: text;
    /* font-weight: inherit; */
    -webkit-transition: all 0.28s ease;
    transition: all 0.28s ease;
    box-shadow: none;
  }
  .ex1-input::placeholder{
    color: transparent;
  }
  .ex1-input:focus::placeholder{
    color: #7b808c;
  }

  .ex1-input:focus ~ .ex1-label,
  .ex1-input.focus ~ .ex1-label,
  .ex1-input.val ~ .ex1-label,
  .ex1-input.complete ~ .ex1-label,
  .ex1-input.invalid ~ .ex1-label{
      font-size: 0.8rem;
      color:#7b808c;
      top: -1rem;
      left: 0;
  }
  .ex1-bar{
    position: relative;
    border-bottom: 0.0625rem solid #999;
    display: block;
  }
  .ex1-bar::before {
    content: '';
    height: 0.125rem;
    width: 0;
    left: 50%;
    bottom: -0.0625rem;
    position: absolute;
    background: #337ab7;
    -webkit-transition: left 0.28s ease, width 0.28s ease;
    transition: left 0.28s ease, width 0.28s ease;
    z-index: 2;
  }
  .ex1-input:focus ~ .ex1-bar::before,
  .ex1-input.focus ~ .ex1-bar::before{
    width: 100%;
    left: 0;
  }
  .ex1-button{
    background: #FFD200;
    color: #fff;
    font-size: 16px;
    font-family: inherit;
    border: none;
    border-radius: 4px;
    padding: 12px 20px;
    display: block;
    width: 85%;
    letter-spacing: .5px;
    transition: .2s;
    cursor: pointer;
    border-radius: 3em !important;
    position: absolute;
    bottom: 1rem;
    left: 1.8rem;
  }
  .ex1-button-disabled{
    background: #F1F1F1;
    color: #fff;
    font-size: 16px;
    font-family: inherit;
    border: none;
    border-radius: 4px;
    padding: 12px 20px;
    display: block;
    width: 100%;
    letter-spacing: .5px;
    transition: .2s;
    border-radius: 3em !important;
    /* cursor: pointer; */
  }
  .ex1-button:hover,
  .ex1-button:focus{
    background: #FFD200;
    color: #fff;
    outline: none;
    border-radius: 3em !important;
  }
  .ex1-button.submit {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 20px;
    color: #fff !important;
    /* color: transparent!important; */
    transition-duration: 0s;
    border-radius: 3em !important;
  }

  .ex1-wrap .token {
    color: #555;
    padding: 10px;
    text-align: center;
    font-weight: 500;
  }

  .ex1-wrap .error {
    color: #e41029;
    padding: 10px;
    text-align: center;
    font-weight: 500;
    font-size: 11px;
    padding-bottom: 0px;
    position: absolute;
    bottom: 3px;
    left: 0;
  }

  .ex1-fieldset {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
